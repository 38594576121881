exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-booking-tsx": () => import("./../../../src/templates/booking.tsx" /* webpackChunkName: "component---src-templates-booking-tsx" */),
  "component---src-templates-confirmation-tsx": () => import("./../../../src/templates/confirmation.tsx" /* webpackChunkName: "component---src-templates-confirmation-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-default-tsx": () => import("./../../../src/templates/default.tsx" /* webpackChunkName: "component---src-templates-default-tsx" */),
  "component---src-templates-frontpage-tsx": () => import("./../../../src/templates/frontpage.tsx" /* webpackChunkName: "component---src-templates-frontpage-tsx" */),
  "component---src-templates-pricing-tsx": () => import("./../../../src/templates/pricing.tsx" /* webpackChunkName: "component---src-templates-pricing-tsx" */),
  "component---src-templates-story-tsx": () => import("./../../../src/templates/story.tsx" /* webpackChunkName: "component---src-templates-story-tsx" */)
}

