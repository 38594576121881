import { ComponentStyleConfig, extendTheme } from '@chakra-ui/react';

const colors = {
  brand: {
    green: '#68744D',
    orange: '#D85938',
    background: '#F5F3F1',
  },
};

const fonts = {
  heading: 'Heebo, sans-serif',
  body: 'Heebo, sans-serif',
};

const fontSizes = {
  xs: '14px',
  sm: '16px',
  md: '18px',
  lg: '24px',
  xl: '32px',
  '2xl': '64px',
  '3xl': '96px',
};

const lineHeights = {
  shorter: 1.1,
  short: 1.2,
  base: 1.5,
};

const Container: ComponentStyleConfig = {
  baseStyle: {
    maxWidth: '1312px',
  },
};

const Heading: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: '900',
  },
};

const sharedButtonProps = {
  display: { base: 'flex', md: 'inline-flex' },
  borderRadius: '24px',
  paddingX: '32px',
  minHeight: '42px',
  fontSize: 'md',
  fontWeight: '500',
  transition: 'transform 0.15s',
  _focus: {
    boxShadow: 'none',
  },
  _hover: {
    transform: { md: 'translateY(-6px)' },
  },
};

const Button: ComponentStyleConfig = {
  variants: {
    slideshowNavigation: {
      ...sharedButtonProps,
      paddingX: '6px',
      background: 'white',
      color: 'black',
      _hover: {},
    },
    whiteToOrange: {
      ...sharedButtonProps,
      background: 'white',
      color: 'black',
      _hover: {
        ...sharedButtonProps._hover,
        background: 'brand.orange',
        color: 'white',
      },
    },
    blackToOrange: {
      ...sharedButtonProps,
      background: 'black',
      color: 'white',
      _hover: {
        ...sharedButtonProps._hover,
        background: 'brand.orange',
      },
    },
    whiteToBlack: {
      ...sharedButtonProps,
      background: 'white',
      color: 'black',
      _hover: {
        ...sharedButtonProps._hover,
        background: 'black',
        color: 'white',
      },
    },
  },
};

const components = {
  Container,
  Heading,
  Button,
};

const styles = {
  global: {
    body: {
      color: 'black',
    },
    'ul, ol': {
      marginLeft: '1em',
    },
    'ul:not(:last-child), o:not(:last-child)': {
      marginBottom: '1em',
    },
    'p:not(:last-child)': {
      marginBottom: '1em',
    },
    'p a': {
      textDecoration: 'underline',
    },
  },
};

export default extendTheme({
  colors,
  fonts,
  fontSizes,
  lineHeights,
  components,
  styles,
});
